import styled from "styled-components";
import {BgImage} from "gbimage-bridge";

export const LandingWrapper = styled(BgImage)`
    width: 100%;
    height: fit-content;
    min-height: 620px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 0 0 150px;
    color: ${props => props.fontColor ? props.fontColor : props.theme.colors.primary};
    
    @media(${props => props.theme.breakPoints.md}){
        align-items: center;
        padding: 20px;
    }
`;

export const InfoWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

export const Title = styled.h1`
    width: 100%;
    max-width: 800px;
    text-align: left;
    color: inherit;

    & h1,h2,h3 {
        margin: 0;
        font-size: ${props => props.theme.fonts.sizes.title};
        color: inherit;
        border-bottom: 0;
    }
`;

export const Description = styled.p`
    width: 100%;
    max-width: 800px;
    color: inherit;
`;

export const MobileImage = styled(BgImage)`
    display: none;
    width: 100%;
    min-width: 200px;
    max-width: 200px;
    height: auto;
    min-height: 300px;
    background-color: cyan;

    @media(${props => props.theme.breakPoints.md}){
        display: block;
    }
`;