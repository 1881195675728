import React from "react";
import {SidebarWrapper, Title, Description, SocialIcons} from "./styles";
import {Button, linkResolver, SocialMedia} from "@HichiHachi/react-slices";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";

export const Sidebar = ({data: {title, description, image, actionlabel, button_vers, link, body}, social}) => {
    return(
        <SidebarWrapper>
            <GatsbyImage image={getImage(image.localFile)} alt={image.alt}/>
            <Title>{title}</Title>
            <SocialIcons>
                <SocialMedia social={social} items={body[0]?.items} primary={body[0]?.primary}/>
            </SocialIcons>
            <Description>
                <RichText render={description.richText}/>
            </Description>
            <Button to={linkResolver(link)} button_vers={button_vers}>{actionlabel}</Button>
        </SidebarWrapper>
    )
}