const theme = {
    name: "Creative Celtic",
    colors: {
        background: "white",
        primary: "#1A725E",
        secondary: "#BD762A",
        tertiary: "#e54573",
        headings: "white",
        text: "#545454",
        button: "#BD762A",
        altButton: "#e54573",
        borders: "#1A725E"
    },
    fonts: {
        sizes: {
            title: "2.7rem",
            heading2: "2.6rem",
            heading3: "2.4rem",
            heading4: "2.2rem",
            heading5: "2rem",
            heading6: "1.8rem",
            subHeading: "1.7rem",
            p: "1.2rem",
            desktop: "16px",
            tablet: "14px",
            mobile: "12px",
            xs: "10px"
        }
    },
    breakPoints: {
        lg: "max-width: 1280px",
        md: "max-width: 950px",
        sm: "max-width: 650px",
        xs: "max-width: 400px"
    },
    sizes: {
        page: "1400px"
    },
    buttonStyles: {
        primary: `
            background-color: #1A725E;
        `,
        secondary: `
            background-color: #BD762A;
        `,
        alternative: `
            background-color: #e54573;
        `
    },
    overwrite: {
        global: `
            h1,h2,h3,h4,h5,h6 {
                font-family: 'Brittany';
                font-weight: normal;
            }
            p, a, li {
                font-family: 'PT Serif';
            }
        `,
        action_grid:`
            & .gatsby-image-wrapper{
                & picture {
                        >img {
                            object-fit: contain !important;
                        }
                }
            }
        `,
        action_card:`
            @media(max-width: 600px){
                max-height: 300px !important;
            }
        `,
        button:`
            color: white;
            border-radius: 4px;
        `,
        banner:`
            padding: 50px 0;
            .infotext {
                border: 5px solid white !important;

                @media(max-width: 950px){
                    & h6 {
                        margin: 1.4rem auto 30px auto !important;
                    }
                }

                & p {
                    padding: 12px 10px !important;
                    & a, & a:link, & a:visited, & a:hover, & a:active  {
                        color: #e54573;
                        font-weight: bold;
                    }
                }
            }
            h1,h2,h3,h4,h5,h6 {
                color: inherit !important;
                border: none;
            }
            margin: 20px 0;
        `,
        hero:`
            margin: 0 auto;
            justify-content: center;

            & h1, h2 {
                font-size: 2.3rem;
                font-weight: bold;
            }

            @media(min-width: 600px){
                >div {
                    margin-right: 50px;
                }
            }
            @media(max-width: 600px){
                >div {
                    margin-right: 0;
                }
                >img {
                    display: none;
                }
            }


            &::before {
                height: 85%;
                opacity: 1;
                background-repeat: repeat !important;
                background-size: contain !important;
            }

            &::after {
                background-size: 400px !important;
                background-repeat: repeat !important;
            }
        `,
        content_block: `
            max-width: 1400px;
            margin: 30px auto;
        `,
        PageWrapper: `
            display: flex;
            flex-direction: row-reverse;
        `,
        page_grid:`
            & p{
                font-style: italic;
            }

            & .gatsby-image-wrapper{
                >img {
                    opacity: 1 !important;
                }
                & picture {
                    >img {
                        object-fit: scale-down !important;
                    }
                }
            }
        `,
        title: `
            & h1 {
                font-weight: bold !important;
            }
        `,
        testimonialWrapper: `
            background-color: white;
        `,
        form_block: `
            & button {
                border: 0 !important;
            }
        `,
        form_label: `
            font-family: "Brittany" !important;
        `,
        form_input: `
            & input, textarea{
                font-family: "PT-Serif" !important;
            }
        `,
    }
}

export default theme;