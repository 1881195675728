import React from 'react'
import {FooterWrapper} from "./styles";
import {Link} from "gatsby";

export const Footer = () => {
    return (
        <FooterWrapper>
            <p>&copy;CreativeCeltic {new Date().getFullYear()}</p>
            <Link to="/privacy">Privacy</Link>
            <Link to="/cookies">Cookies</Link>
        </FooterWrapper>
    )
}
