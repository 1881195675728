import styled from "styled-components";

export const SidebarWrapper = styled.div`
    margin: 30px 10px;
    width: 300px;
    height: 100%;
    text-align: center;
    
    & img {
        width: 100%;
        height: 100%;
        max-height: 200px;
        object-fit: contain;
    }

    @media(max-width: 1145px){
        display: none;
    }
`;

export const Title = styled.p`
    width: 100%;
    font-size: ${props=> props.theme.fonts.sizes.heading};
    font-weight: bold;
    padding: 20px 0;
    border-bottom: 3px solid ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.primary};
`;

export const SocialIcons = styled.div`
    padding: 0 10px;
    & a {
        font-size: 2rem;
    }
`;

export const Description = styled.p`
    width: 100%;
    height: 200px;
    font-size: ${props => props.theme.fonts.sizes.p};
`;