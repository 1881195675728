import styled from "styled-components";

export const FooterWrapper = styled.footer`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100px;
    min-height: 100px;
    text-align: center;
    background-color: ${props => props.theme.colors.primary};
    margin-top: 150px;
    font-size: 1.2rem;
    
    >p {
        color: white !important;
        width: fit-content;
    }

    >a {
        color: white;
        text-decoration: none;
    }

    @media(${props => props.theme.breakPoints.sm}){
        display: none;
    }
`;