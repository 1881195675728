import React from 'react';
import Layout from 'components/layout';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import {ThemeProvider} from "styled-components";
import Theme from 'themes/main';
import { GlobalStyle } from '@HichiHachi/react-slices';

export const wrapPageElement = ({ element }) => {
	return (
	<ThemeProvider theme={Theme}>
		<GlobalStyle/>
			<PrismicPreviewProvider>
			<Layout>
				{element}
			</Layout>
		</PrismicPreviewProvider>
    </ThemeProvider>
	);
};