import React from "react";
import {LandingWrapper, MobileImage, InfoWrapper, Title, Description} from "./styles";
import {Button} from "@HichiHachi/react-slices";
import {getImage} from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";

export const Landing = ({primary: {flipped, landing_title, description, font_color, button_vers, action, actionlabel, image}}) => {
    const backgroundImage = image.localFile;
    const mobImage = image.thumbnails.Mobile;
    return(
        <LandingWrapper flipped={flipped} fontColor={font_color} image={getImage(backgroundImage)}>
            <MobileImage image={getImage(mobImage)}/>
            <InfoWrapper>
                {!!landing_title && <Title><RichText render={landing_title.richText}/></Title>}
                {!!description && <Description>{description}</Description>}
                {!!action && <Button to={action} version={button_vers}>{actionlabel}</Button>}
            </InfoWrapper>
        </LandingWrapper>
    )
}