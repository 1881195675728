import React from 'react';
import PropTypes from 'prop-types';
import { Footer } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import { MainWrapper, Header } from '@HichiHachi/react-slices';
import { FaBars } from 'react-icons/fa';
import "../fonts/typography.css";

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		{
			prismicNavigation {
				data {
					...brandAndNavigationQuery
				}
			}
		}
	`);
	let links = [
		{nav_item: "Home", nav_link: {uid: ""}},
		{nav_item: "Blog", nav_link: {uid: "blog"}}
	]
	links = [links[0]].concat(data?.prismicNavigation?.data?.header.concat([links[1]]));
	return (
		<>
			<Header
				links={links}
				image={data.prismicNavigation.data.brand}
				Icon={<FaBars/>}
			/>
			<MainWrapper>{children}</MainWrapper>
			<Footer />
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
